@import url(fontiran.css); /* لینک فایلی که وظیفه بارگذاری فونت ها را برعهده دارد */

body {
    background-color: #e5e5e5 !important;
    color: #4a4a4a !important;
    text-align: right;
}

a:link, a:visited {
    /* color: #f5a344 !important; */
    text-decoration: none !important;
  }

  #codeInput {
      text-align: center;
      direction: ltr !important;
  }